import "./bootstrap";

console.log("v2.3");

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function () {
  $(this).on('submit', function (e) {
    var $form = $(this);

    if ($form.data('submitted') === true) {
      // Previously submitted - don't submit again
      e.preventDefault();
    } else {
      // Mark it so that the next submit can be ignored
      $form.data('submitted', true);
    }
  });

  // Keep chainability
  return this;
};


//map locations click events

var locationSelected = "";

var locationId = "";

var isELearning = false;

var isOnlyELearning = false;

$('.map-location').click(function () {

  locationSelected = locationId = $(this).data("maplocation");

  // Change Location

  $('.selected').removeClass('selected');
  $(this).addClass('selected');

  $('.location-contact-details').hide();
  $('[data-officeLocation=' + locationId + ']').fadeIn()

  // Mobile view, remove map, insert city
  if ($(window).width() < 768) {
    $('#map-desktop').hide()
    $('#map-contact-desktop').show()
    // $('#map-contact-mobile-modal.location-contacts-outer').modal();
  }

});

$("#map-back-btn").click(function () {
  $('#map-desktop').show()
  $('#map-contact-desktop').hide()
})

$('.form-modal-link').click(function () {
  isELearning = $(this).is('#button-e-learning')
  isOnlyELearning = $(this).is('#button-e-learning-only')
  if (isOnlyELearning) {
    locationId = "e-learning"
    $('#angebot-weiterbildungsort-elearning').val(locationId)
    $('#angebot-form-modal-elearning').modal('show');
  } else if (isELearning) {
    if (locationSelected == "") {
      locationId = "e-learning"
    } else {
      locationId = locationSelected + "-mit-e-learning"
    }
    $('#angebot-weiterbildungsort-elearning').val(locationId)
    $('#angebot-form-modal-elearning').modal('show');
  } else {
    if (locationSelected == "") {
      locationId = "duisburg";
    } else {
      locationId = locationSelected
    }
    $('#angebot-weiterbildungsort').val(locationId)
    $('#angebot-form-modal').modal('show');

  }

})



//hide navbar dropdown on link click
$('#navbarNav.show .nav-item a').on('click', function () {
  $('.navbar-toggler').click()
});

//remove flicker on desktop navbar link click
$('.navbar-nav a').on('click', function () {
  $('.navbar-collapse').collapse('hide');
});


//MAILCHIMP CODE
Parsley.addMessages('de', {
  defaultMessage: "Die Eingabe scheint nicht korrekt zu sein.",
  type: {
    email: "Die Eingabe muss eine gültige E-Mail-Adresse sein.",
    url: "Die Eingabe muss eine gültige URL sein.",
    number: "Die Eingabe muss eine Zahl sein.",
    integer: "Die Eingabe muss eine Zahl sein.",
    digits: "Die Eingabe darf nur Ziffern enthalten.",
    alphanum: "Die Eingabe muss alphanumerisch sein."
  },
  notblank: "Die Eingabe darf nicht leer sein.",
  required: "Dies ist ein Pflichtfeld.",
  pattern: "Sonderzeichen sind nicht gültig.",
  min: "Die Eingabe muss größer oder gleich %s sein.",
  max: "Die Eingabe muss kleiner oder gleich %s sein.",
  range: "Die Eingabe muss zwischen %s und %s liegen.",
  minlength: "Die Eingabe ist zu kurz. Es müssen mindestens %s Zeichen eingegeben werden.",
  maxlength: "Die Eingabe ist zu lang. Es dürfen höchstens %s Zeichen eingegeben werden.",
  length: "Die Länge der Eingabe ist ungültig. Es müssen zwischen %s und %s Zeichen eingegeben werden.",
  mincheck: "Wählen Sie mindestens %s Angaben aus.",
  maxcheck: "Wählen Sie maximal %s Angaben aus.",
  check: "Wählen Sie zwischen %s und %s Angaben.",
  equalto: "Dieses Feld muss dem anderen entsprechen."
});

Parsley.setLocale('de');


$('#form-whitepaper').parsley({
  inputs: Parsley.options.inputs
});
$('#angebot-form').parsley({
  inputs: Parsley.options.inputs
});
$('#angebot-form-elearning').parsley({
  inputs: Parsley.options.inputs
});
// $('#form-map-elearning').parsley({
//   inputs: Parsley.options.inputs
// });

window.Parsley.addValidator('contactdetails', {
  validateString: function (value, elem) {
    if ($(elem).is(':checked')) {
      return value !== "";
    }
  },
  messages: {
    de: 'Dies ist ein Pflichtfeld.'
  }
});


// Recaptcha v3 for forms

//submit whitepaper form
$("#form-whitepaper").on("submit", submitWhitepaperForm);

var wpSubmitting = false;

function submitWhitepaperForm(e) {
  e.preventDefault();
  if (!wpSubmitting) {
    wpSubmitting = true;
    $(this).attr("disabled", "disabled");
    var form = $(this);
    form.parsley().validate();

    $("#submit-download-whitepaper").addClass("disabled");

    grecaptcha.ready(function () {
      grecaptcha.execute('6Lei38wZAAAAANKMWvtCV_JKspFePJmGS83Wsiyk', {
        action: 'submit'
      }).then(function (token) {
        var recaptchaResponseWhitepaper = document.getElementById('recaptchaResponseWhitepaper');
        recaptchaResponseWhitepaper.value = token;

        if (form.parsley().isValid()) {
          var formData = form.serialize();

          $('.whitepaper-invalid').text("");
          $('.whitepaper-invalid').hide();

          $.ajax({
            type: "POST",
            url: "/api/whitepaper-submit",
            data: formData, // serializes the form's elements.
            success: function (responseObj) {
              // var responseObj = jQuery.parseJSON(data);                  
              if (responseObj.success) {
                // console.log(responseObj);
                $('.whitepaper-section').fadeOut('slow', function () {
                  $('.salutation__value').text(responseObj.form_data.salutation);
                  $('.vorname__value').text(responseObj.form_data.firstname);
                  $('.nachname__value').text(responseObj.form_data.lastname);
                  $('.email__value').text(responseObj.form_data.email);
                  $(".whitepaper-valid").fadeIn('slow');
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "whitepaperConversion",
                  whitepaperConversion: "1",
                });
              } else {
                $('.whitepaper-invalid').text("Es ist leider ein Fehler aufgetreten!");
                $('.whitepaper-invalid').show();

                $("#submit-download-whitepaper").removeAttr("disabled");
                $("#submit-download-whitepaper").removeClass("disabled");
                wpSubmitting = false;
              }
            },
            error: function (responseObj, ajaxOptions, thrownError) {
              let errors = responseObj.responseJSON.errors;
              for (var key of Object.keys(errors)) {
                let errMsg = errors[key];
                console.log(errMsg);
                $('.whitepaper-invalid').append("<p>" + errMsg + "</p>");
              }
              $('.whitepaper-invalid').show();
              $("#submit-download-whitepaper").removeAttr("disabled");
              $("#submit-download-whitepaper").removeClass("disabled");
              wpSubmitting = false;
            }
          });
        } else {
          $("#submit-download-whitepaper").removeClass("disabled");
          $("#submit-download-whitepaper").removeAttr("disabled");
          wpSubmitting = false;
        }
      });
    })
  } else {
    return false;
  }
}


//submit map form
$("#angebot-form").on("submit", submitAngebotForm);

var angebotSubmitting = false;

function submitAngebotForm(e) {
  e.preventDefault();
  if (!angebotSubmitting) {
    angebotSubmitting = true;
    console.log("angebotSubmitting click!")
    $(this).attr("disabled", "disabled");
    var form = $(this);
    form.parsley().validate();

    $("#angebot-absenden").addClass("disabled");
    grecaptcha.ready(function () {
      grecaptcha.execute('6Lei38wZAAAAANKMWvtCV_JKspFePJmGS83Wsiyk', {
        action: 'submit'
      }).then(function (token) {
        var recaptchaResponseBeratung = document.getElementById('recaptchaResponseBeratung');
        recaptchaResponseBeratung.value = token;
        if (form.parsley().isValid()) {
          var formData = form.serialize();
          $.ajax({
            type: "POST",
            url: "/api/personaloffer-submit",
            data: formData, // serializes the form's elements.
            success: function (responseObj) {
              // var responseObj = jQuery.parseJSON(data);
              if (responseObj.success) {
                $('.salutation__value').text(responseObj.form_data.salutation);
                $('.vorname__value').text(responseObj.form_data.firstname);
                $('.nachname__value').text(responseObj.form_data.lastname);
                $('.email__value').text(responseObj.form_data.email);
                $('.phone__value').text(responseObj.form_data.phone);
                $('.message__value').text(responseObj.form_data.message);
                $('.weiterbildungsstandort__value').text(responseObj.form_data.location);


                switchModals("#angebot-form-modal", "#thank-you-modal");

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "angebotConversion",
                  angebotConversion: "1",
                });

              } else {
                $('.kontakt-form-invalid').text("Es ist leider ein Fehler aufgetreten!");
                $('.kontakt-form-invalid').show();

                $("#angebot-absenden").removeAttr("disabled");
                $("#angebot-absenden").removeClass("disabled");
                angebotSubmitting = false;
              }
            },
            error: function (responseObj, ajaxOptions, thrownError) {
              let errors = responseObj.responseJSON.errors;
              for (var key of Object.keys(errors)) {
                let errMsg = errors[key];
                console.log(errMsg);
                $('.kontakt-form-invalid').append("<p>" + errMsg + "</p>");
              }
              $('.kontakt-form-invalid').show();
              $("#angebot-absenden").removeAttr("disabled");
              $("#angebot-absenden").removeClass("disabled");
              angebotSubmitting = false;
            }
          });
        } else {
          $("#angebot-absenden").removeAttr("disabled");
          $("#angebot-absenden").removeClass("disabled");
          angebotSubmitting = false;
        };
      });
    })
  } else {
    return false;
  }
}


var angebotELSubmitting = false;

//submit map elearning form
$("#angebot-form-elearning").on("submit", submitAngebotElearningForm);

function submitAngebotElearningForm(e) {
  e.preventDefault();
  console.log("click!")
  if (!angebotELSubmitting) {
    console.log("angebotELSubmitting click!")
    angebotELSubmitting = true;
    $(this).attr("disabled", "disabled");
    var form = $(this);
    form.parsley().validate();
    $("#angebot-absenden-elearning").addClass("disabled");
    grecaptcha.ready(function () {
      grecaptcha.execute('6Lei38wZAAAAANKMWvtCV_JKspFePJmGS83Wsiyk', {
        action: 'submit'
      }).then(function (token) {
        var recaptchaResponseBeratungElearning = document.getElementById('recaptchaResponseBeratungElearning');
        recaptchaResponseBeratungElearning.value = token;


        if (form.parsley().isValid()) {

          var formData = form.serialize();
          $.ajax({
            type: "POST",
            url: "/api/personaloffer-submit",
            data: formData, // serializes the form's elements.
            success: function (responseObj) {
              // var responseObj = jQuery.parseJSON(data);

              // if (responseObj.errors) {
              //   console.log(responseObj.errors);
              //   responseObj.errors.forEach(function(e) {
              //     $('.kontakt-form-invalid').append("<p>"+e+"</p>");
              //   })
              //   // $('.kontakt-form-invalid').text(responseObj.message);
              //   $('.kontakt-form-invalid').show();
              //   $("#angebot-absenden-elearning").removeAttr("disabled");
              //   $("#angebot-absenden-elearning").removeClass("disabled");
              //   angebotELSubmitting = false;
              // } else 


              if (responseObj.success) {

                $('.salutation__value').text(responseObj.form_data.salutation);
                $('.vorname__value').text(responseObj.form_data.firstname);
                $('.nachname__value').text(responseObj.form_data.lastname);
                $('.email__value').text(responseObj.form_data.email);
                $('.phone__value').text(responseObj.form_data.phone);
                $('.message__value').text(responseObj.form_data.message);
                $('.weiterbildungsstandort__value').text(responseObj.form_data.location);


                // $('#thank-you-modal').modal('show');

                switchModals("#angebot-form-modal-elearning", "#thank-you-modal");

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: "angebotELearningConversion",
                  angebotELearningConversion: "1",
                });



              } else {
                $('.kontakt-form-invalid').text("Es ist leider ein Fehler aufgetreten!");
                $('.kontakt-form-invalid').show();
                $("#angebot-absenden-elearning").removeAttr("disabled");
                $("#angebot-absenden-elearning").removeClass("disabled");
                angebotELSubmitting = false;
              }
            },
            error: function (responseObj, ajaxOptions, thrownError) {
              let errors = responseObj.responseJSON.errors;
              for (var key of Object.keys(errors)) {
                let errMsg = errors[key];
                console.log(errMsg);
                $('.kontakt-form-invalid').append("<p>" + errMsg + "</p>");
              }
              $('.kontakt-form-invalid').show();
              $("#angebot-absenden-elearning").removeAttr("disabled");
              $("#angebot-absenden-elearning").removeClass("disabled");
              angebotELSubmitting = false;
            }
          });
        } else {
          $("#angebot-absenden-elearning").removeAttr("disabled");
          $("#angebot-absenden-elearning").removeClass("disabled");
          angebotELSubmitting = false;
        };
      });
    })
  } else {
    return false;
  }
}

//Hide the fromModal and open toModal
function switchModals(fromModal, toModal) {
  $(fromModal).on('hidden.bs.modal', function (e) {
    $(toModal).modal('show');
    //clear this function so it doesn't show up if they exit the window again
    $(fromModal).off();
  });
  $(fromModal).modal('hide');
}
